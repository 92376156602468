import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import login_logo from "../../../assets/KENTA_4.0.png";
import { Button, Form, ForgotPasswordButton } from "../styles";
import { checkCredentials, signIn } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { showLoading, hideLoading } from "../../../store/modules/login/actions";
import { InputAuth } from "../../../components/Login/Input";
import { useToast } from "../../../hooks/toast";
import { PermissionsService } from "../../../services/permissions";
import { setPermissions } from "../../../store/modules/permission/actions";
import { AuthScreen } from "..";
import { UserCustomerProfileService } from "../../../services/user_customer_profile";
import { setPoliceUnit } from "../../../store/modules/policeUnit/actions";

import { globalConfigurationsUtils } from "../../../services/utils/globalConfigurations";
import { LogOperation } from "../../../utils/entities/logOperation";
import { setIsAdmin } from "../../../store/modules/profile/actions";
import { CustomerCertificateService } from "../../../services/customerCertificate";
import { insertTolocalStorage } from "../../../utils/sessionStorageEncrypt";
import { updateUser } from "../../../store/modules/user/actions";
import { SystemObjectService } from "../../../services/systemObject";
import { setScreenStatus } from "../../../store/modules/screen_controll/actions";
import { updateGlobalConfigurationsAction } from "../../../store/modules/globalConfiguration/actions";
import { getValueFromlocalStorage, deleteFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { HearingService } from "../../../services/hearing";
import { clearCookies } from "../../../utils/cookies";

type typesInput = "email" | "password" | "text" | undefined;

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState<typesInput>("password");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const onClickSignIn = async (e: any) => {
    e.preventDefault();
    try {
      if (email && password) {
        try {
          if (!/^[\w*0-9.-]+@[\w*0-9.]+\.[\w]+$/gi.test(email)) {
            addToast({
              type: "warning",
              title: "Email inválido",
              description: "Para continuar insira um email válido",
            });

            return;
          }

          setErrorEmail(false);
          setErrorPassword(false);
          setLoading(true);
          dispatch(showLoading());

          const { body } = await checkCredentials({ email, password });
          if (!body.ok) {
            addToast({
              type: "warning",
              title: "Aviso",
              description: body.message,
            });

            setLoading(false);
            dispatch(hideLoading());
            return;
          }

          const response = await signIn({ email, password });

          if (!response.status) {
            if (response.message) {
              addToast({
                type: "error",
                title: "Erro",
                description: response.message,
              });
              setLoading(false);
              dispatch(hideLoading());
            }

            if (
              (response.body &&
                response.body.response &&
                response.body.response.data &&
                response.body.response.data.message &&
                response.body.response.data.message.includes("senha")) ||
              response.body.response.data.message.includes("usuário") ||
              response.body.response.data.message.includes("vinculado")
            ) {
              if (response.body.response.data.message.includes("vinculado")) {
                addToast({
                  type: "error",
                  title: "Usuário não vinculado",
                  description:
                    "O usuário ainda não foi vinculado a nenhuma unidade de polícia",
                });
              } else {
                addToast({ type: "error", title: "Usuário ou senha inválido" });
              }
            }
            
            setLoading(false);
            dispatch(hideLoading());
            return;
          }

          await HearingService.logoutUna();
          
          const user = getValueFromlocalStorage("@Kenta:user");
          let userJson;
          if (user) {
            userJson = JSON.parse(user);

            dispatch(updateUser(userJson));

            await globalConfigurationsUtils.setLocalstorage(
              userJson.customerId
            );

            await getMenu();

            if (userJson) {
              await setPoliceUnitDefaultAndUpdatePermissions();
            }
          }
          await LogOperation.setMachineIP();

          if (userJson.customerId)
            recuperarTempoDeGravacaoPeloUsuario(userJson.customerId);

          navigate("/dashboard/procedure");
          await LogOperation.postLogOperation("Login", 4, 0);
          dispatch(hideLoading());
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      } else {
        if (!email) {
          setErrorEmail(true);
        }

        if (!password) {
          setErrorPassword(true);
        }

        addToast({
          type: "error",
          title: "Preencha todos os campos",
          description:
            "Para fazer o login é necessário preencher todos os campos",
        });
      }
    } catch (error) {
      dispatch(hideLoading());
      setLoading(false);
    }
  };

  async function recuperarTempoDeGravacaoPeloUsuario(customerId: number) {
    const response = await CustomerCertificateService.getCustomerCertificate(
      customerId
    );
    if (response.data && response.data.certificateType === 2) {
      setarTempoLimiteGravacao(response.data.limitRecord);
    }
  }

  function setarTempoLimiteGravacao(tempo: number) {
    insertTolocalStorage("timeToCount", String(tempo), "encrypt-time");
  }

  const updatePermissions = async (
    customerId: number,
    userCustomerProfile: number,
    unitId: number
  ) => {
    const authToken = getValueFromlocalStorage("@Kenta:access_Token");
    if (authToken) {
      const { data } = await PermissionsService.getPermissions(
        authToken,
        customerId,
        userCustomerProfile
      );

      const user = getValueFromlocalStorage("@Kenta:user");

      if (data && data.length > 0) {
        dispatch(setPermissions(data));
        if (user) {
          const partsedUser = JSON.parse(user);

          const { success, body } =
            await UserCustomerProfileService.getPermissionToSeeIfHasPermissionClassified(
              partsedUser.id,
              userCustomerProfile,
              unitId
            );

          if (success) {
            dispatch(setIsAdmin(body as boolean));
          }
        }
      }
    }
  };

  const setPoliceUnitDefaultAndUpdatePermissions = async () => {
    const authToken = getValueFromlocalStorage("@Kenta:access_Token");
    const user = getValueFromlocalStorage("@Kenta:user");
    let userJson = null;

    if (user) userJson = JSON.parse(user) as any;

    if (authToken && userJson) {
      const response = await UserCustomerProfileService.getAllByUserCustomer(
        authToken,
        userJson.id
      );

      if (response && response.data && response.data.length > 0) {
        dispatch(setPoliceUnit(response.data[0]));
        await updatePermissions(
          response.data[0].customerId,
          response.data[0].profileId,
          response.data[0].unitId
        );
      }
    }
  };

  const clearUserDataFromLocalStorage = () => {
    deleteFromlocalStorage("@Kenta:user");
    deleteFromlocalStorage("@Kenta:access_Token");
    deleteFromlocalStorage("@Kenta:refresh_Token");
    deleteFromlocalStorage("@Kenta:expireIn");
    dispatch(setScreenStatus(false));
  };

  async function getMenu() {
    const { data } = await SystemObjectService.getAll();
    if (data) {
      if (data.ok) {
        localStorage.setItem("KentaMenu", JSON.stringify(data.data));
      }
    }
  }

  useEffect(clearUserDataFromLocalStorage, []);
  useEffect(() => {
    clearCookies()
  }, []);

  return (
    <AuthScreen>
      <img src={login_logo} alt="" style={{ paddingBottom: "30px" }} />
      <Form onSubmit={onClickSignIn}>
        <InputAuth
          id="singIn_email"
          maxLength={200}
          error={errorEmail}
          label="Seu e-mail"
          type="text"
          disabled={loading}
          value={email}
          autoFocus={true}
          onChange={(email: string) => {
            setEmail(email);
            if (errorEmail) {
              setErrorEmail(false);
            }
          }}
        />
        <InputAuth
          id="singIn_password"
          maxLength={50}
          error={errorPassword}
          label="Sua senha"
          disabled={loading}
          autoFocus={false}
          onChange={(password: string) => {
            setPassword(password);
            if (errorPassword && password) {
              setErrorPassword(false);
            }
          }}
          value={password}
          setType={(type: typesInput) => setPasswordType(type)}
          icon={true}
          type={passwordType}
        />
        <Button disabled={loading} type="submit">
          Entrar
        </Button>
      </Form>
      <ForgotPasswordButton onClick={() => navigate("/forgotPassword")}>
        Esqueci a minha senha
      </ForgotPasswordButton>
      <ForgotPasswordButton onClick={() => navigate("/importCertificate")}>
        Importar certificado
      </ForgotPasswordButton>
    </AuthScreen>
  );
};
